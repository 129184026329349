import React from 'react';
import {graphql} from 'gatsby';
import {Page} from '../components/Page';
import {Banner} from '../components/Banner';
import PortfolioCarousel from '../components/PortfolioCarousel';
import PageHeader from '../components/PageHeader';
import {Markdown} from '../components/Markdown';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import {useAccordionButton} from "react-bootstrap";
import {Helmet} from "react-helmet";

export default function Home({data: {allFaqHome, home, titleSEO, descriptionSEO}}) {
	return <Page>
		<Helmet>
			<title>{home.titleSEO}</title>
			<meta name="description" content={home.descriptionSEO}  />
			<link rel="canonical" href="https://www.icit.com.au/" />
		</Helmet>

		<Banner>
			<PortfolioCarousel />
		</Banner>
		<PageHeader>{home.title}</PageHeader>
		<Markdown>
			{home.bodyText}
		</Markdown>

		<Container fluid>
			<Row>
				<Col>
					<Accordion defaultActiveKey="0">
						{allFaqHome.nodes
							.map((faq, index) => <FAQ key={index} faq={faq} index={index} />)}
					</Accordion>
				</Col>
			</Row>
		</Container>

		<Markdown>
			{home.bodyTextBottom}
		</Markdown>
	</Page>;
}

function FAQToggle({children, eventKey}) {
	const click = useAccordionButton(eventKey);

	return <Card.Header onClick={click} style={{cursor: 'pointer'}}>
		{children}
	</Card.Header>;
}

function FAQ({faq, index}) {
	return <Accordion.Item eventKey={`${index}`}>
		<Accordion.Header>{faq.Question}</Accordion.Header>
		<Accordion.Body>
			<Markdown>{faq.Answer}</Markdown>
		</Accordion.Body>
	</Accordion.Item>
}


export const query = graphql`
    query {
        home {
			title
			bodyText
			bodyTextBottom
			descriptionSEO
			titleSEO
        }
		allFaqHome(
			filter: {active: {eq: true}, published: {eq: true}}
		) {
			nodes {
				Question
				Answer
				id
				published
				active
			}
		}
    }
`;
