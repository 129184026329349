import React from 'react';

export function Banner({children}) {

	return (<div className="Banner">
		<div className="constrain-1000w">
			{children}
		</div>
	</div>);
}
